<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#20419B" />
    <path
      d="M16.496 23.0481C16.224 23.1441 15.776 23.1441 15.504 23.0481C13.184 22.2561 8.00002 18.9521 8.00002 13.3521C8.00002 10.8801 9.99201 8.8801 12.448 8.8801C13.904 8.8801 15.192 9.5841 16 10.6721C16.808 9.5841 18.104 8.8801 19.552 8.8801C22.008 8.8801 24 10.8801 24 13.3521C24 18.9521 18.816 22.2561 16.496 23.0481Z"
      stroke="#FCFCFD"
      stroke-width="1.43"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.27686 14.944H12.776C13.376 14.944 14.048 15.4 14.272 15.96L15.184 18.264C15.392 18.784 15.72 18.784 15.928 18.264L17.76 13.616C17.936 13.168 18.264 13.152 18.488 13.576L19.32 15.152C19.568 15.624 20.208 16.008 20.736 16.008H23.6"
      stroke="#FCFCFD"
      stroke-width="1.43"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'HealthRecord'
}
</script>