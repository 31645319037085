<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.5 9H24V7.5C24 6.70435 23.6839 5.94129 23.1213 5.37868C22.5587 4.81607 21.7956 4.5 21 4.5H15C14.2044 4.5 13.4413 4.81607 12.8787 5.37868C12.3161 5.94129 12 6.70435 12 7.5V9H7.5C6.30653 9 5.16193 9.47411 4.31802 10.318C3.47411 11.1619 3 12.3065 3 13.5V27C3 28.1935 3.47411 29.3381 4.31802 30.182C5.16193 31.0259 6.30653 31.5 7.5 31.5H28.5C29.6935 31.5 30.8381 31.0259 31.682 30.182C32.5259 29.3381 33 28.1935 33 27V13.5C33 12.3065 32.5259 11.1619 31.682 10.318C30.8381 9.47411 29.6935 9 28.5 9ZM15 7.5H21V9H15V7.5ZM30 27C30 27.3978 29.842 27.7794 29.5607 28.0607C29.2794 28.342 28.8978 28.5 28.5 28.5H7.5C7.10218 28.5 6.72064 28.342 6.43934 28.0607C6.15804 27.7794 6 27.3978 6 27V19.5H12V21C12 21.3978 12.158 21.7794 12.4393 22.0607C12.7206 22.342 13.1022 22.5 13.5 22.5C13.8978 22.5 14.2794 22.342 14.5607 22.0607C14.842 21.7794 15 21.3978 15 21V19.5H21V21C21 21.3978 21.158 21.7794 21.4393 22.0607C21.7206 22.342 22.1022 22.5 22.5 22.5C22.8978 22.5 23.2794 22.342 23.5607 22.0607C23.842 21.7794 24 21.3978 24 21V19.5H30V27ZM30 16.5H6V13.5C6 13.1022 6.15804 12.7206 6.43934 12.4393C6.72064 12.158 7.10218 12 7.5 12H28.5C28.8978 12 29.2794 12.158 29.5607 12.4393C29.842 12.7206 30 13.1022 30 13.5V16.5Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'Workspace'
}
</script>