<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 15.417V16C9 17.1935 9.47411 18.3381 10.318 19.182C11.1619 20.0259 12.3065 20.5 13.5 20.5C14.6935 20.5 15.8381 20.0259 16.682 19.182C17.5259 18.3381 18 17.1935 18 16V14.33C17.3325 14.0941 16.7699 13.6298 16.4116 13.0192C16.0534 12.4086 15.9225 11.691 16.0422 10.9932C16.1619 10.2954 16.5244 9.66246 17.0656 9.20613C17.6069 8.7498 18.292 8.49951 19 8.49951C19.708 8.49951 20.3931 8.7498 20.9344 9.20613C21.4756 9.66246 21.8381 10.2954 21.9578 10.9932C22.0775 11.691 21.9466 12.4086 21.5884 13.0192C21.2301 13.6298 20.6675 14.0941 20 14.33V16C20 17.7239 19.3152 19.3772 18.0962 20.5962C16.8772 21.8152 15.2239 22.5 13.5 22.5C11.7761 22.5 10.1228 21.8152 8.90381 20.5962C7.68482 19.3772 7 17.7239 7 16V15.417C5.60193 15.1803 4.33281 14.4562 3.4176 13.3732C2.50239 12.2901 2.00018 10.918 2 9.5L2 4.5C2 3.96957 2.21071 3.46086 2.58579 3.08579C2.96086 2.71071 3.46957 2.5 4 2.5H5C5.26522 2.5 5.51957 2.60536 5.70711 2.79289C5.89464 2.98043 6 3.23478 6 3.5C6 3.76522 5.89464 4.01957 5.70711 4.20711C5.51957 4.39464 5.26522 4.5 5 4.5H4V9.5C4 10.5609 4.42143 11.5783 5.17157 12.3284C5.92172 13.0786 6.93913 13.5 8 13.5C9.06087 13.5 10.0783 13.0786 10.8284 12.3284C11.5786 11.5783 12 10.5609 12 9.5V4.5H11C10.7348 4.5 10.4804 4.39464 10.2929 4.20711C10.1054 4.01957 10 3.76522 10 3.5C10 3.23478 10.1054 2.98043 10.2929 2.79289C10.4804 2.60536 10.7348 2.5 11 2.5H12C12.5304 2.5 13.0391 2.71071 13.4142 3.08579C13.7893 3.46086 14 3.96957 14 4.5V9.5C13.9998 10.918 13.4976 12.2901 12.5824 13.3732C11.6672 14.4562 10.3981 15.1803 9 15.417ZM19 12.5C19.2652 12.5 19.5196 12.3946 19.7071 12.2071C19.8946 12.0196 20 11.7652 20 11.5C20 11.2348 19.8946 10.9804 19.7071 10.7929C19.5196 10.6054 19.2652 10.5 19 10.5C18.7348 10.5 18.4804 10.6054 18.2929 10.7929C18.1054 10.9804 18 11.2348 18 11.5C18 11.7652 18.1054 12.0196 18.2929 12.2071C18.4804 12.3946 18.7348 12.5 19 12.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExaminationScheduleIcon'
}
</script>
