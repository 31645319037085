<template>
  <svg
    version="1.1"
    width="24"
    height="25"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="131 -131 512 512"
    style="enable-background: new 131 -131 512 512"
    xml:space="preserve"
  >
    <g id="XMLID_2_">
      <path
        fill="currentColor"
        id="XMLID_7_"
        d="M195.2,157.1h431.9c4.7,0,8.4,1.9,11.2,4.7s4.7,6.5,4.7,11.2v111.7h-64.2v-64.2H195.2v64.2H131V-18.8   c0-4.7,1.9-8.4,4.7-11.2c2.8-2.8,6.5-4.7,11.2-4.7h31.7c4.7,0,8.4,1.9,11.2,4.7c2.8,2.8,4.7,6.5,4.7,11.2v175.9L195.2,157.1   L195.2,157.1z M338.6,77.1c0-17.7-6.5-32.6-18.6-45.6s-27.9-18.6-45.6-18.6s-32.6,6.5-45.6,18.6s-18.6,27.9-18.6,45.6   s6.5,32.6,18.6,45.6c12.1,13,27.9,18.6,45.6,18.6s32.6-6.5,45.6-18.6S338.6,94.7,338.6,77.1z M643,141.3v-15.8   c0-26.1-9.3-49.3-27.9-68c-18.6-18.6-41-27.9-68-27.9H371.2c-4.7,0-8.4,1.9-11.2,4.7s-4.7,6.5-4.7,11.2v95.9H643V141.3z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'RoomManagementIcon'
}
</script>
