<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.71 13.21C16.6904 12.4387 17.406 11.3809 17.7572 10.1839C18.1085 8.98697 18.0779 7.71027 17.6698 6.53147C17.2617 5.35267 16.4963 4.33039 15.4801 3.60686C14.4639 2.88332 13.2474 2.49451 12 2.49451C10.7525 2.49451 9.53611 2.88332 8.51993 3.60686C7.50374 4.33039 6.73834 5.35267 6.33021 6.53147C5.92208 7.71027 5.89151 8.98697 6.24276 10.1839C6.59401 11.3809 7.3096 12.4387 8.29 13.21C6.61007 13.883 5.14428 14.9994 4.04889 16.4399C2.95349 17.8805 2.26956 19.5913 2.07 21.39C2.05555 21.5213 2.06711 21.6542 2.10402 21.7811C2.14093 21.9079 2.20246 22.0263 2.28511 22.1293C2.45202 22.3375 2.69478 22.4708 2.96 22.5C3.22521 22.5292 3.49116 22.4518 3.69932 22.2849C3.90749 22.118 4.04082 21.8752 4.07 21.61C4.28958 19.6552 5.22168 17.8498 6.68822 16.5388C8.15475 15.2278 10.0529 14.503 12.02 14.503C13.9871 14.503 15.8852 15.2278 17.3518 16.5388C18.8183 17.8498 19.7504 19.6552 19.97 21.61C19.9972 21.8557 20.1144 22.0827 20.2991 22.247C20.4838 22.4114 20.7228 22.5015 20.97 22.5H21.08C21.3421 22.4698 21.5817 22.3373 21.7466 22.1313C21.9114 21.9252 21.9881 21.6624 21.96 21.4C21.7595 19.5962 21.0719 17.881 19.9708 16.4382C18.8698 14.9954 17.3969 13.8795 15.71 13.21ZM12 12.5C11.2089 12.5 10.4355 12.2654 9.77772 11.8259C9.11992 11.3864 8.60723 10.7616 8.30448 10.0307C8.00173 9.29983 7.92251 8.49557 8.07686 7.71964C8.2312 6.94372 8.61216 6.23099 9.17157 5.67158C9.73098 5.11217 10.4437 4.7312 11.2196 4.57686C11.9956 4.42252 12.7998 4.50173 13.5307 4.80448C14.2616 5.10724 14.8863 5.61993 15.3259 6.27772C15.7654 6.93552 16 7.70888 16 8.5C16 9.56087 15.5786 10.5783 14.8284 11.3284C14.0783 12.0786 13.0609 12.5 12 12.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrgPersonnelIcon'
}
</script>
