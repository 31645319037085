<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87868 14.6213C11.0503 15.7929 12.9497 15.7929 14.1213 14.6213C15.2929 13.4497 15.2929 11.5502 14.1213 10.3786C12.9497 9.20707 11.0503 9.20707 9.87868 10.3786C8.70711 11.5502 8.70711 13.4497 9.87868 14.6213Z"
      fill="currentColor"
    />
    <circle cx="3.99974" cy="12.4997" r="2.99974" fill="currentColor" />
    <path
      d="M15 4.5C15 6.15685 13.6569 7.5 12 7.5C10.3431 7.5 9 6.15685 9 4.5C9 2.84315 10.3431 1.5 12 1.5C13.6569 1.5 15 2.84315 15 4.5Z"
      fill="currentColor"
    />
    <path
      d="M15 20.5C15 22.1569 13.6569 23.5 12 23.5C10.3431 23.5 9 22.1569 9 20.5C9 18.8431 10.3431 17.5 12 17.5C13.6569 17.5 15 18.8431 15 20.5Z"
      fill="currentColor"
    />
    <circle cx="19.9997" cy="12.5002" r="2.99974" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'CircleIcon'
}
</script>
