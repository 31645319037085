<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.666656" width="32" height="32" rx="16" fill="#20419B" />
    <path
      d="M9.16666 23.593V10.4001C9.16666 9.79621 9.6628 9.30007 10.2667 9.30007H17.7775C17.7705 9.39895 17.7667 9.49917 17.7667 9.60007C17.7667 9.70096 17.7705 9.80118 17.7775 9.90007H10.2667H9.76666V10.4001V20.0001V20.5001H10.2667H23.0667H23.5667V20.0001V13.908C23.774 13.8441 23.9744 13.765 24.1667 13.673V20.0001C24.1667 20.6039 23.6705 21.1001 23.0667 21.1001H11.8667H11.6595L11.5131 21.2465L9.16666 23.593ZM22.2667 11.5001C21.2148 11.5001 20.3667 10.6519 20.3667 9.60007C20.3667 8.54821 21.2148 7.70007 22.2667 7.70007C23.3185 7.70007 24.1667 8.54821 24.1667 9.60007C24.1667 10.6519 23.3185 11.5001 22.2667 11.5001Z"
      fill="white"
      stroke="#FCFCFD"
    />
  </svg>
</template>

<script>
export default {
  name: 'Chat'
}
</script>