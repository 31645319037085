<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.25 17.5H25V5H26.25C26.5815 5 26.8995 4.8683 27.1339 4.63388C27.3683 4.39946 27.5 4.08152 27.5 3.75C27.5 3.41848 27.3683 3.10054 27.1339 2.86612C26.8995 2.6317 26.5815 2.5 26.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75C2.5 4.08152 2.6317 4.39946 2.86612 4.63388C3.10054 4.8683 3.41848 5 3.75 5H5V17.5H3.75C3.41848 17.5 3.10054 17.6317 2.86612 17.8661C2.6317 18.1005 2.5 18.4185 2.5 18.75C2.5 19.0815 2.6317 19.3995 2.86612 19.6339C3.10054 19.8683 3.41848 20 3.75 20H13.75V21.4375L8.0625 25.1875C7.82917 25.3319 7.64985 25.5491 7.55221 25.8056C7.45457 26.0621 7.44404 26.3435 7.52224 26.6066C7.60044 26.8696 7.76303 27.0996 7.98492 27.2611C8.2068 27.4225 8.47566 27.5065 8.75 27.5C8.99575 27.5028 9.2362 27.4285 9.4375 27.2875L13.75 24.4375V26.25C13.75 26.5815 13.8817 26.8995 14.1161 27.1339C14.3505 27.3683 14.6685 27.5 15 27.5C15.3315 27.5 15.6495 27.3683 15.8839 27.1339C16.1183 26.8995 16.25 26.5815 16.25 26.25V24.4375L20.5625 27.2875C20.7638 27.4285 21.0042 27.5028 21.25 27.5C21.5171 27.4979 21.7764 27.4104 21.9901 27.2502C22.2038 27.09 22.3606 26.8656 22.4375 26.6098C22.5143 26.3541 22.5073 26.0804 22.4173 25.8289C22.3273 25.5775 22.1592 25.3614 21.9375 25.2125L16.25 21.4625V20H26.25C26.5815 20 26.8995 19.8683 27.1339 19.6339C27.3683 19.3995 27.5 19.0815 27.5 18.75C27.5 18.4185 27.3683 18.1005 27.1339 17.8661C26.8995 17.6317 26.5815 17.5 26.25 17.5ZM22.5 17.5H7.5V5H22.5V17.5Z"
      fill="currentColor" />
  </svg>

</template>

<script>
export default {
  name: 'TQM'
}
</script>