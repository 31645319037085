<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8163 15.6205C15.8163 17.6135 14.4583 19.0215 12.2863 19.0215H6.06929C3.89929 19.0215 2.55029 17.6135 2.55029 15.6205V8.66049C2.55029 6.66849 3.89929 5.25049 6.08029 5.25049H12.2863C14.4583 5.25049 15.8163 6.66849 15.8163 8.66049V15.6205Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 11.5823L20.097 8.22827C20.747 7.69327 21.734 8.15827 21.734 9.00527V15.8773C21.734 16.7253 20.747 17.1883 20.097 16.6543L16 13.3003"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'MeetingIcon'
}
</script>
