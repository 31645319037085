<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.62 21.3101C12.28 21.4301 11.72 21.4301 11.38 21.3101C8.48 20.3201 2 16.1901 2 9.1901C2 6.1001 4.49 3.6001 7.56 3.6001C9.38 3.6001 10.99 4.4801 12 5.8401C13.01 4.4801 14.63 3.6001 16.44 3.6001C19.51 3.6001 22 6.1001 22 9.1901C22 16.1901 15.52 20.3201 12.62 21.3101Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.34595 11.1801H7.96987C8.71987 11.1801 9.55987 11.7501 9.83987 12.4501L10.9799 15.3301C11.2399 15.9801 11.6499 15.9801 11.9099 15.3301L14.1999 9.52011C14.4199 8.96011 14.8299 8.94011 15.1099 9.47011L16.1499 11.4401C16.4599 12.0301 17.2599 12.5101 17.9199 12.5101H21.4999"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CarePlanIcon'
}
</script>
