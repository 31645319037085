<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.15722 21.2714V18.2047C9.1572 17.4246 9.79312 16.7908 10.581 16.7856H13.4671C14.2587 16.7856 14.9005 17.4209 14.9005 18.2047V18.2047V21.2809C14.9003 21.9432 15.4343 22.4845 16.103 22.5H18.0271C19.9451 22.5 21.5 20.9607 21.5 19.0618V19.0618V10.3378C21.4898 9.59083 21.1355 8.88935 20.538 8.43303L13.9577 3.1853C12.8049 2.27157 11.1662 2.27157 10.0134 3.1853L3.46203 8.44256C2.86226 8.89702 2.50739 9.59967 2.5 10.3474V19.0618C2.5 20.9607 4.05488 22.5 5.97291 22.5H7.89696C8.58235 22.5 9.13797 21.9499 9.13797 21.2714V21.2714"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 9H15C15.2761 9 15.5 9.22386 15.5 9.5C15.5 9.77614 15.2761 10 15 10H9C8.72386 10 8.5 9.77614 8.5 9.5C8.5 9.22386 8.72386 9 9 9Z"
      stroke="currentColor"
    />
    <path
      d="M12.5 6.5L12.5 12.5C12.5 12.7761 12.2761 13 12 13C11.7239 13 11.5 12.7761 11.5 12.5L11.5 6.5C11.5 6.22386 11.7239 6 12 6C12.2761 6 12.5 6.22386 12.5 6.5Z"
      stroke="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrgWorkspaceIcon'
}
</script>
