<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 15.5H20V12.5C20 12.2348 19.8946 11.9804 19.7071 11.7929C19.5196 11.6054 19.2652 11.5 19 11.5H13V9.5H15C15.2652 9.5 15.5196 9.39464 15.7071 9.20711C15.8946 9.01957 16 8.76522 16 8.5V2.5C16 2.23478 15.8946 1.98043 15.7071 1.79289C15.5196 1.60536 15.2652 1.5 15 1.5H9C8.73478 1.5 8.48043 1.60536 8.29289 1.79289C8.10536 1.98043 8 2.23478 8 2.5V8.5C8 8.76522 8.10536 9.01957 8.29289 9.20711C8.48043 9.39464 8.73478 9.5 9 9.5H11V11.5H5C4.73478 11.5 4.48043 11.6054 4.29289 11.7929C4.10536 11.9804 4 12.2348 4 12.5V15.5H2C1.73478 15.5 1.48043 15.6054 1.29289 15.7929C1.10536 15.9804 1 16.2348 1 16.5V22.5C1 22.7652 1.10536 23.0196 1.29289 23.2071C1.48043 23.3946 1.73478 23.5 2 23.5H8C8.26522 23.5 8.51957 23.3946 8.70711 23.2071C8.89464 23.0196 9 22.7652 9 22.5V16.5C9 16.2348 8.89464 15.9804 8.70711 15.7929C8.51957 15.6054 8.26522 15.5 8 15.5H6V13.5H18V15.5H16C15.7348 15.5 15.4804 15.6054 15.2929 15.7929C15.1054 15.9804 15 16.2348 15 16.5V22.5C15 22.7652 15.1054 23.0196 15.2929 23.2071C15.4804 23.3946 15.7348 23.5 16 23.5H22C22.2652 23.5 22.5196 23.3946 22.7071 23.2071C22.8946 23.0196 23 22.7652 23 22.5V16.5C23 16.2348 22.8946 15.9804 22.7071 15.7929C22.5196 15.6054 22.2652 15.5 22 15.5ZM7 17.5V21.5H3V17.5H7ZM10 7.5V3.5H14V7.5H10ZM21 21.5H17V17.5H21V21.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrgManageIcon'
}
</script>
