<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 8.5H18V5.5C18 4.70435 17.6839 3.94129 17.1213 3.37868C16.5587 2.81607 15.7956 2.5 15 2.5H5C4.20435 2.5 3.44129 2.81607 2.87868 3.37868C2.31607 3.94129 2 4.70435 2 5.5V17.5C2.00099 17.6974 2.06039 17.8901 2.17072 18.0539C2.28105 18.2176 2.43738 18.345 2.62 18.42C2.73868 18.476 2.86882 18.5034 3 18.5C3.13161 18.5008 3.26207 18.4755 3.38391 18.4258C3.50574 18.376 3.61656 18.3027 3.71 18.21L6.52 15.39H8V16.83C8 17.6256 8.31607 18.3887 8.87868 18.9513C9.44129 19.5139 10.2044 19.83 11 19.83H17.92L20.29 22.21C20.3834 22.3027 20.4943 22.376 20.6161 22.4258C20.7379 22.4755 20.8684 22.5008 21 22.5C21.1312 22.5034 21.2613 22.476 21.38 22.42C21.5626 22.345 21.7189 22.2176 21.8293 22.0539C21.9396 21.8901 21.999 21.6974 22 21.5V11.5C22 10.7044 21.6839 9.94129 21.1213 9.37868C20.5587 8.81607 19.7956 8.5 19 8.5ZM8 11.5V13.39H6.11C5.97839 13.3892 5.84793 13.4145 5.72609 13.4642C5.60426 13.514 5.49344 13.5873 5.4 13.68L4 15.09V5.5C4 5.23478 4.10536 4.98043 4.29289 4.79289C4.48043 4.60536 4.73478 4.5 5 4.5H15C15.2652 4.5 15.5196 4.60536 15.7071 4.79289C15.8946 4.98043 16 5.23478 16 5.5V8.5H11C10.2044 8.5 9.44129 8.81607 8.87868 9.37868C8.31607 9.94129 8 10.7044 8 11.5ZM20 19.09L19 18.09C18.9074 17.9955 18.7969 17.9203 18.6751 17.8688C18.5532 17.8173 18.4223 17.7906 18.29 17.79H11C10.7348 17.79 10.4804 17.6846 10.2929 17.4971C10.1054 17.3096 10 17.0552 10 16.79V11.5C10 11.2348 10.1054 10.9804 10.2929 10.7929C10.4804 10.6054 10.7348 10.5 11 10.5H19C19.2652 10.5 19.5196 10.6054 19.7071 10.7929C19.8946 10.9804 20 11.2348 20 11.5V19.09Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ChatIcon'
}
</script>
