<template>
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 14.5H19V11.5C19 11.2348 18.8946 10.9804 18.7071 10.7929C18.5196 10.6054 18.2652 10.5 18 10.5H12V8.5H14C14.2652 8.5 14.5196 8.39464 14.7071 8.20711C14.8946 8.01957 15 7.76522 15 7.5V1.5C15 1.23478 14.8946 0.98043 14.7071 0.792893C14.5196 0.605357 14.2652 0.5 14 0.5H8C7.73478 0.5 7.48043 0.605357 7.29289 0.792893C7.10536 0.98043 7 1.23478 7 1.5V7.5C7 7.76522 7.10536 8.01957 7.29289 8.20711C7.48043 8.39464 7.73478 8.5 8 8.5H10V10.5H4C3.73478 10.5 3.48043 10.6054 3.29289 10.7929C3.10536 10.9804 3 11.2348 3 11.5V14.5H1C0.734784 14.5 0.48043 14.6054 0.292893 14.7929C0.105357 14.9804 0 15.2348 0 15.5V21.5C0 21.7652 0.105357 22.0196 0.292893 22.2071C0.48043 22.3946 0.734784 22.5 1 22.5H7C7.26522 22.5 7.51957 22.3946 7.70711 22.2071C7.89464 22.0196 8 21.7652 8 21.5V15.5C8 15.2348 7.89464 14.9804 7.70711 14.7929C7.51957 14.6054 7.26522 14.5 7 14.5H5V12.5H17V14.5H15C14.7348 14.5 14.4804 14.6054 14.2929 14.7929C14.1054 14.9804 14 15.2348 14 15.5V21.5C14 21.7652 14.1054 22.0196 14.2929 22.2071C14.4804 22.3946 14.7348 22.5 15 22.5H21C21.2652 22.5 21.5196 22.3946 21.7071 22.2071C21.8946 22.0196 22 21.7652 22 21.5V15.5C22 15.2348 21.8946 14.9804 21.7071 14.7929C21.5196 14.6054 21.2652 14.5 21 14.5ZM6 16.5V20.5H2V16.5H6ZM9 6.5V2.5H13V6.5H9ZM20 20.5H16V16.5H20V20.5Z" fill="#20419B"/>
  </svg>
</template>

<script>
export default {
  name: 'Organization'
}
</script>