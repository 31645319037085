<template>
  <svg
    xmlns:x="http://ns.adobe.com/Extensibility/1.0/"
    xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/"
    xmlns:graph="http://ns.adobe.com/Graphs/1.0/"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 125"
    style="enable-background: new 0 0 100 100; margin-top: 5px"
    xml:space="preserve"
    width="24"
  >
    <switch>
      <foreignObject
        requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
        x="0"
        y="0"
        width="1"
        height="1"
      />
      <g i:extraneous="self">
        <g>
          <path
            fill="#03267a"
            d="M65.8,55.2H34.2L17.6,81.8c-0.7,1-0.4,2-0.1,2.6c0.2,0.5,0.9,1.3,2.2,1.3h60.6c1.2,0,1.9-0.7,2.2-1.3     c0.3-0.5,0.6-1.5-0.1-2.6L65.8,55.2z"
          />
          <path
            fill="#03267a"
            d="M92.5,75.6L67.1,34.8c-0.7-1.2-1.1-2.6-1.1-4V9.3h2.7c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4H31.4C29.5,2.5,28,4,28,5.9     s1.5,3.4,3.4,3.4h2.7v21.5c0,1.4-0.4,2.8-1.1,4L7.5,75.6c-2.8,4.5-2.9,9.9-0.4,14.5c2.6,4.6,7.2,7.4,12.5,7.4h60.6     c5.3,0,10-2.8,12.5-7.4C95.4,85.5,95.2,80.1,92.5,75.6z M86.9,86.9c-1.4,2.4-3.8,3.9-6.6,3.9H19.7c-2.8,0-5.3-1.5-6.6-3.9     c-1.4-2.4-1.3-5.3,0.2-7.7l25.4-40.8c1.4-2.3,2.2-4.9,2.2-7.6V9.3h18.4v21.5c0,2.7,0.7,5.3,2.2,7.6l25.4,40.8     C88.2,81.5,88.3,84.4,86.9,86.9z"
          />
        </g>
      </g>
    </switch>
  </svg>
</template>

<script>
export default {
  name: 'OrgLabManageIcon'
}
</script>
