<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.41788 12.718C9.12923 12.718 9.70588 12.1471 9.70588 11.443C9.70588 10.7388 9.12923 10.168 8.41788 10.168C7.70654 10.168 7.12988 10.7388 7.12988 11.443C7.12988 12.1471 7.70654 12.718 8.41788 12.718Z"
      fill="currentColor"
    />
    <path
      d="M8.4 13.051C7.62 13.061 7 13.705 7 14.477V14.836C7 14.919 7.068 14.986 7.152 14.986H9.683C9.70283 14.9861 9.72249 14.9824 9.74086 14.9749C9.75923 14.9674 9.77595 14.9564 9.79007 14.9425C9.80418 14.9286 9.81541 14.912 9.82312 14.8937C9.83083 14.8754 9.83487 14.8558 9.835 14.836V14.454C9.8341 14.2673 9.79614 14.0826 9.72331 13.9107C9.65047 13.7388 9.54423 13.5831 9.41073 13.4526C9.27724 13.3221 9.11915 13.2194 8.94564 13.1504C8.77214 13.0815 8.58667 13.0477 8.4 13.051Z"
      fill="currentColor"
    />
    <path
      d="M11.6799 12.531C12.4498 12.531 13.0739 11.9131 13.0739 11.151C13.0739 10.3888 12.4498 9.771 11.6799 9.771C10.91 9.771 10.2859 10.3888 10.2859 11.151C10.2859 11.9131 10.91 12.531 11.6799 12.531Z"
      fill="currentColor"
    />
    <path
      d="M11.661 12.8922C11.2566 12.8992 10.8711 13.065 10.5877 13.3536C10.3043 13.6422 10.1457 14.0307 10.146 14.4352V14.8352C10.146 14.9192 10.214 14.9862 10.298 14.9862H13.062C13.0819 14.9864 13.1017 14.9827 13.1202 14.9753C13.1387 14.9679 13.1555 14.9569 13.1697 14.943C13.1839 14.9291 13.1953 14.9124 13.203 14.8941C13.2108 14.8758 13.2149 14.8561 13.215 14.8362V14.4112C13.215 13.5662 12.517 12.8812 11.661 12.8922ZM15.243 12.1752C16.125 12.1752 16.84 11.4672 16.84 10.5942C16.84 9.72116 16.125 9.01416 15.243 9.01416C14.361 9.01416 13.646 9.72116 13.646 10.5942C13.646 11.4672 14.361 12.1742 15.243 12.1742V12.1752ZM15.243 12.5882C14.273 12.5882 13.486 13.3672 13.486 14.3282V14.8362C13.486 14.9192 13.554 14.9862 13.638 14.9862H16.848C16.8678 14.9863 16.8875 14.9825 16.9059 14.975C16.9242 14.9676 16.9409 14.9566 16.9551 14.9426C16.9692 14.9287 16.9804 14.9121 16.9881 14.8939C16.9958 14.8756 16.9999 14.856 17 14.8362V14.3272C17 13.3672 16.213 12.5872 15.243 12.5872V12.5882Z"
      fill="currentColor"
    />
    <path
      d="M6 4C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6ZM6 2H18C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2V2Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'AdmissionIcon'
}
</script>
