<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.333328" width="32" height="32" rx="16" fill="#20419B" />
    <g clip-path="url(#clip0_18543_1206)">
      <path
        d="M14.6363 17.6971C15.5735 18.6343 17.0931 18.6343 18.0304 17.6971C18.9677 16.7598 18.9677 15.2402 18.0304 14.303C17.0931 13.3657 15.5735 13.3657 14.6363 14.303C13.699 15.2402 13.699 16.7598 14.6363 17.6971Z"
        fill="white"
      />
      <circle cx="9.93313" cy="15.9997" r="2.39979" fill="white" />
      <path
        d="M18.7333 9.60007C18.7333 10.9255 17.6588 12.0001 16.3333 12.0001C15.0079 12.0001 13.9333 10.9255 13.9333 9.60007C13.9333 8.27458 15.0079 7.20007 16.3333 7.20007C17.6588 7.20007 18.7333 8.27458 18.7333 9.60007Z"
        fill="white"
      />
      <path
        d="M18.7333 22.4001C18.7333 23.7255 17.6588 24.8001 16.3333 24.8001C15.0079 24.8001 13.9333 23.7255 13.9333 22.4001C13.9333 21.0746 15.0079 20.0001 16.3333 20.0001C17.6588 20.0001 18.7333 21.0746 18.7333 22.4001Z"
        fill="white"
      />
      <ellipse
        cx="22.7331"
        cy="16.0002"
        rx="2.39979"
        ry="2.39979"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_18543_1206">
        <rect
          width="19.2"
          height="19.2"
          fill="white"
          transform="translate(6.73334 6.40006)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconCircle'
}
</script>