<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0917 16.9083L15 13.8416C16.2001 12.3453 16.7813 10.446 16.624 8.53434C16.4668 6.62267 15.5831 4.84391 14.1546 3.56379C12.7262 2.28368 10.8615 1.59951 8.94411 1.65198C7.02671 1.70445 5.20228 2.48956 3.84596 3.84587C2.48965 5.20219 1.70454 7.02662 1.65207 8.94402C1.5996 10.8614 2.28377 12.7261 3.56388 14.1545C4.844 15.583 6.62276 16.4667 8.53443 16.6239C10.4461 16.7812 12.3454 16.2 13.8417 14.9999L16.9083 18.0666C16.9858 18.1447 17.078 18.2067 17.1795 18.249C17.2811 18.2913 17.39 18.3131 17.5 18.3131C17.61 18.3131 17.7189 18.2913 17.8205 18.249C17.922 18.2067 18.0142 18.1447 18.0917 18.0666C18.2419 17.9112 18.3258 17.7035 18.3258 17.4874C18.3258 17.2713 18.2419 17.0636 18.0917 16.9083ZM9.16668 14.9999C8.01295 14.9999 6.88514 14.6578 5.92585 14.0168C4.96657 13.3759 4.21889 12.4648 3.77738 11.3989C3.33587 10.333 3.22035 9.16012 3.44543 8.02856C3.67051 6.89701 4.22608 5.8576 5.04189 5.0418C5.8577 4.22599 6.8971 3.67042 8.02865 3.44534C9.16021 3.22026 10.3331 3.33578 11.399 3.77729C12.4649 4.2188 13.3759 4.96647 14.0169 5.92576C14.6579 6.88505 15 8.01286 15 9.16659C15 10.7137 14.3854 12.1974 13.2915 13.2914C12.1975 14.3853 10.7138 14.9999 9.16668 14.9999Z"
      fill="#475467"
    />
  </svg>

</template>

<script>
export default {
  name: 'Search'
}
</script>