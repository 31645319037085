<template>
  <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
  >
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.25 8.29008C3.25 6.75108 3.51546 5.96528 3.95033 5.53041C4.3852 5.09553 5.171 4.83008 6.71 4.83008H13.03C14.569 4.83008 15.3548 5.09553 15.7897 5.53041C16.2245 5.96528 16.49 6.75108 16.49 8.29008V16.7101C16.49 18.2476 16.2226 19.0339 15.7864 19.4692C15.4522 19.8028 14.9128 20.0364 13.9902 20.1276C13.7662 20.6803 13.4737 21.1979 13.1231 21.6698C14.6974 21.6598 15.982 21.3933 16.8461 20.5309C17.7274 19.6512 17.99 18.3325 17.99 16.7101V8.29008C17.99 6.66908 17.7305 5.34987 16.8503 4.46975C15.9702 3.58962 14.651 3.33008 13.03 3.33008H6.71C5.089 3.33008 3.7698 3.58962 2.88967 4.46975C2.00954 5.34987 1.75 6.66908 1.75 8.29008V13.5068C2.16586 12.9091 2.67252 12.3794 3.25 11.9375V8.29008Z"
        fill="white"
    />
    <path
        d="M20.02 17.6001L17.24 15.6501V9.34013L20.02 7.39013C21.38 6.44013 22.5 7.02013 22.5 8.69013V16.3101C22.5 17.9801 21.38 18.5601 20.02 17.6001Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M13 9.5C13.8284 9.5 14.5 8.82843 14.5 8C14.5 7.17157 13.8284 6.5 13 6.5C12.1716 6.5 11.5 7.17157 11.5 8C11.5 8.82843 12.1716 9.5 13 9.5Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <circle
        cx="7.5"
        cy="17.5"
        r="5.25"
        stroke="white"
        stroke-width="1.5"
    />
    <path d="M7.5 14.5V20.5" stroke="white" stroke-width="1.5" />
    <path d="M10.5 17.5L4.5 17.5" stroke="white" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  name: 'CreateMeet'
}
</script>