<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.79 23.25H6.21C3.47 23.25 1.25 21.02 1.25 18.28V10.87C1.25 9.51 2.09 7.8 3.17 6.96L8.56 2.76C10.18 1.5 12.77 1.44 14.45 2.62L20.63 6.95C21.82 7.78 22.75 9.56001 22.75 11.01V18.29C22.75 21.02 20.53 23.25 17.79 23.25ZM9.48 3.94L4.09 8.14C3.38 8.7 2.75 9.97001 2.75 10.87V18.28C2.75 20.19 4.3 21.75 6.21 21.75H17.79C19.7 21.75 21.25 20.2 21.25 18.29V11.01C21.25 10.05 20.56 8.72 19.77 8.18L13.59 3.85C12.45 3.05 10.57 3.09 9.48 3.94Z"
      fill="currentColor"
    />
    <path
      d="M12 19.25C11.59 19.25 11.25 18.91 11.25 18.5V15.5C11.25 15.09 11.59 14.75 12 14.75C12.41 14.75 12.75 15.09 12.75 15.5V18.5C12.75 18.91 12.41 19.25 12 19.25Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon'
}
</script>
