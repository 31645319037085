<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.05 2.51001C10.5 2.51001 9.22998 3.77001 9.22998 5.33001C9.22998 6.89001 10.49 8.15001 12.05 8.15001C13.61 8.15001 14.87 6.89001 14.87 5.33001C14.87 3.77001 13.61 2.51001 12.05 2.51001Z"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M5.04998 14.37C3.49998 14.37 2.22998 15.63 2.22998 17.19C2.22998 18.75 3.48998 20.01 5.04998 20.01C6.60998 20.01 7.86998 18.75 7.86998 17.19C7.86998 15.63 6.59998 14.37 5.04998 14.37Z"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M18.9499 14.37C17.3999 14.37 16.1299 15.63 16.1299 17.19C16.1299 18.75 17.3899 20.01 18.9499 20.01C20.5099 20.01 21.7699 18.75 21.7699 17.19C21.7699 15.63 20.5099 14.37 18.9499 14.37Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M17.6255 6.47439C17.1946 6.12895 16.5652 6.19825 16.2198 6.62917C15.8743 7.06009 15.9436 7.68945 16.3745 8.03489L17.6255 6.47439ZM19.748 11.5L18.7795 11.7493L19.748 11.5ZM18.9053 12.345C18.9957 12.8898 19.5106 13.2583 20.0555 13.1679C20.6003 13.0775 20.9687 12.5626 20.8784 12.0178L18.9053 12.345ZM16.3745 8.03489C17.539 8.96836 18.3974 10.2646 18.7795 11.7493L20.7164 11.2508C20.2238 9.3369 19.119 7.67162 17.6255 6.47439L16.3745 8.03489ZM18.7795 11.7493C18.8298 11.9446 18.8719 12.1433 18.9053 12.345L20.8784 12.0178C20.8353 11.7582 20.7812 11.5024 20.7164 11.2508L18.7795 11.7493Z"
      fill="currentColor"
    />
    <path
      d="M15.0756 21.3908C15.5903 21.1903 15.8449 20.6106 15.6445 20.096C15.444 19.5814 14.8643 19.3267 14.3497 19.5272L15.0756 21.3908ZM9.66208 20.7161L9.93043 19.7528L9.66208 20.7161ZM9.35163 19.5638C8.8346 19.3697 8.25807 19.6314 8.0639 20.1484C7.86973 20.6655 8.13146 21.242 8.64849 21.4362L9.35163 19.5638ZM14.3497 19.5272C12.959 20.0689 11.4073 20.1642 9.93043 19.7528L9.39372 21.6794C11.2975 22.2097 13.2921 22.0855 15.0756 21.3908L14.3497 19.5272ZM9.93043 19.7528C9.73613 19.6986 9.54302 19.6357 9.35163 19.5638L8.64849 21.4362C8.89477 21.5286 9.14341 21.6097 9.39372 21.6794L9.93043 19.7528Z"
      fill="currentColor"
    />
    <path
      d="M6.37453 6.47439C6.80545 6.12895 7.43481 6.19825 7.78025 6.62917C8.12569 7.06009 8.05639 7.68945 7.62547 8.03489L6.37453 6.47439ZM4.25204 11.5L5.22047 11.7493L4.25204 11.5ZM5.09468 12.345C5.00432 12.8898 4.48939 13.2583 3.94455 13.1679C3.3997 13.0775 3.03127 12.5626 3.12163 12.0178L5.09468 12.345ZM7.62547 8.03489C6.46101 8.96836 5.6026 10.2646 5.22047 11.7493L3.2836 11.2508C3.77619 9.3369 4.88103 7.67162 6.37453 6.47439L7.62547 8.03489ZM5.22047 11.7493C5.1702 11.9446 5.12813 12.1433 5.09468 12.345L3.12163 12.0178C3.16468 11.7582 3.21883 11.5024 3.2836 11.2508L5.22047 11.7493Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ConsultationIcon'
}
</script>
