<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16667 5.83333L8 7L10.1667 9.16667H1.66667V10.8333H10.1667L8 13L9.16667 14.1667L13.3333 10L9.16667 5.83333ZM16.6667 15.8333H10V17.5H16.6667C17.5833 17.5 18.3333 16.75 18.3333 15.8333V4.16667C18.3333 3.25 17.5833 2.5 16.6667 2.5H10V4.16667H16.6667V15.8333Z"
      fill="#20419B"
    />
  </svg>
</template>

<script>
export default {
  name: 'Login'
}
</script>