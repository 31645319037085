<template>
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4166 4.83341H17.3333C17.3333 4.25878 17.105 3.70768 16.6986 3.30135C16.2923 2.89502 15.7412 2.66675 15.1666 2.66675H10.8333C10.2586 2.66675 9.70752 2.89502 9.30119 3.30135C8.89486 3.70768 8.66658 4.25878 8.66658 4.83341H7.58325C6.7213 4.83341 5.89465 5.17582 5.28515 5.78532C4.67566 6.39481 4.33325 7.22146 4.33325 8.08342V21.0834C4.33325 21.9454 4.67566 22.772 5.28515 23.3815C5.89465 23.991 6.7213 24.3334 7.58325 24.3334H18.4166C19.2785 24.3334 20.1052 23.991 20.7147 23.3815C21.3242 22.772 21.6666 21.9454 21.6666 21.0834V8.08342C21.6666 7.22146 21.3242 6.39481 20.7147 5.78532C20.1052 5.17582 19.2785 4.83341 18.4166 4.83341ZM10.8333 4.83341H15.1666V5.91675V7.00008H10.8333V4.83341ZM19.4999 21.0834C19.4999 21.3707 19.3858 21.6463 19.1826 21.8494C18.9795 22.0526 18.7039 22.1667 18.4166 22.1667H7.58325C7.29593 22.1667 7.02038 22.0526 6.81722 21.8494C6.61406 21.6463 6.49992 21.3707 6.49992 21.0834V8.08342C6.49992 7.7961 6.61406 7.52055 6.81722 7.31738C7.02038 7.11422 7.29593 7.00008 7.58325 7.00008H8.66658C8.66658 7.57472 8.89486 8.12582 9.30119 8.53215C9.70752 8.93848 10.2586 9.16675 10.8333 9.16675H15.1666C15.7412 9.16675 16.2923 8.93848 16.6986 8.53215C17.105 8.12582 17.3333 7.57472 17.3333 7.00008H18.4166C18.7039 7.00008 18.9795 7.11422 19.1826 7.31738C19.3858 7.52055 19.4999 7.7961 19.4999 8.08342V14.5834V21.0834Z"
      fill="currentColor"
    />
    <path
      d="M10.1965 15.6581H10.5522C10.6626 15.6581 10.7599 15.5857 10.7916 15.48L12.1816 10.8467C12.227 10.6953 12.4455 10.7081 12.473 10.8637L13.8658 18.7561C13.8939 18.9156 14.1198 18.9235 14.159 18.7665L15.0242 15.3058C15.052 15.1945 15.152 15.1165 15.2667 15.1165H17.7799"
      stroke="currentColor"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ElectronicMedicalRecordIcon'
}
</script>
